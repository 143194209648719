body {
  margin: 0;
  font-family: 'Kosugi Maru', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

code {
  font-family: 'Kosugi Maru',sans-serif;
}
